<script lang="ts" setup>
import type { IUpsertLeaseRegisterConfigurationRequest } from '@register'
interface Props {
  highConfidenceScore: number
  title?: string
}
const props = withDefaults(defineProps<Props>(), {
  title: 'Confidence Scores',
})

const configuration = computed(() => {
  return {
    highConfidenceScore: props.highConfidenceScore,
    mediumConfidenceScore: 0.1,
  }
})

// emit close event
defineEmits<{
  (e: 'close'): void
  (e: 'submit', payload: IUpsertLeaseRegisterConfigurationRequest): void
}>()
</script>
<template>
  <ModalBase :title="title" container-class="max-w-4xl" @close="$emit('close')">
    <div>
      <ConfidenceScore
        :configuration="configuration"
        @submit-confidence-score="
          (payload) => {
            $emit('submit', payload)
          }
        "
      />
    </div>
    <template #footer>
      <div></div>
    </template>
  </ModalBase>
</template>
